import * as Sentry from '@sentry/nextjs';
import {RESPONSE_STATUS_CODE} from 'core/constants';
import {withBasicData} from 'core/next/controllers';
import {Error404} from 'modules/errors/components/Error404';
import {NextPage} from 'next';

export const Error404Page: NextPage = () => {
    return <Error404 />;
};

Error404Page.getInitialProps = withBasicData(async (_, ctx) => {
    await Sentry.captureUnderscoreErrorException(ctx);

    if (ctx.res) {
        ctx.res.statusCode = RESPONSE_STATUS_CODE.NOT_FOUND;
    }

    return {
        props: {},
    };
});

export default Error404Page;
